@import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,700&display=swap";
@keyframes slide-left {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-right {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

:root {
  font-family: Montserrat, sans-serif;
  font-size: 10px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.wrapper {
  background-color: #111;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
  position: relative;
}

.wrapper .link {
  color: #555;
  font-size: 2rem;
  text-decoration: none;
  position: absolute;
  top: 40px;
  left: 40px;
}

.wrapper .link:hover {
  color: #a3f9ff;
}

.wrapper .text {
  position: relative;
}

.wrapper .text .big {
  color: #555;
  white-space: nowrap;
  font-size: 12rem;
  animation: 1s slide-left;
  position: absolute;
  bottom: 0;
  left: -80px;
}

.wrapper .text .big.mock {
  left: -140px;
}

.wrapper .text .small {
  color: #ddd;
  font-size: 4rem;
  animation: 2s slide-right;
  position: relative;
  left: 80px;
  transform: translateY(0);
}

.wrapper .text .small.mock {
  left: 120px;
}

@media (width <= 500px) {
  .wrapper .link {
    font-size: 1.5rem;
    top: 15px;
    left: 15px;
  }

  .wrapper .text {
    margin-bottom: 50px;
  }

  .wrapper .text .small {
    font-size: 3rem;
    left: 30px;
  }

  .wrapper .text .big {
    font-size: 6rem;
    left: -30px;
  }

  .wrapper .text .big.mock {
    left: -60px;
  }

  .wrapper .text .small.mock {
    left: 60px;
  }
}

/*# sourceMappingURL=404.2b108bf1.css.map */
