@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,700&display=swap");
@import "variables";

$color-bg: #111;
$color-text: #ddd;
$color-text-inactive: #555;

$font-subtitle: "Montserrat", sans-serif;

@keyframes slide-left {
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes slide-right {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

:root {
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.wrapper {
  position: relative;
  background-color: $color-bg;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper .link {
  position: absolute;
  top: 40px;
  left: 40px;
  color: $color-text-inactive;
  font-size: 2rem;

  text-decoration: none;
}

.wrapper .link:hover {
  color: $color-primary-light;
}

.wrapper .text {
  position: relative;
}

.wrapper .text .big {
  position: absolute;
  left: -80px;
  bottom: 0;
  font-size: 12rem;
  color: $color-text-inactive;
  white-space: nowrap;
  animation: slide-left 1s;
}

.wrapper .text .big.mock {
  left: -140px;
}

.wrapper .text .small {
  position: relative;
  left: 80px;
  font-size: 4rem;
  color: $color-text;
  transform: translateY(0);
  animation: slide-right 2s;
}

.wrapper .text .small.mock {
  left: 120px;
}

@media (max-width: 500px) {
  .wrapper .link {
    top: 15px;
    left: 15px;
    font-size: 1.5rem;
  }

  .wrapper .text {
    margin-bottom: 50px;
  }

  .wrapper .text .small {
    font-size: 3rem;
    left: 30px;
  }

  .wrapper .text .big {
    font-size: 6rem;
    left: -30px;
  }

  .wrapper .text .big.mock {
    left: -60px;
  }

  .wrapper .text .small.mock {
    left: 60px;
  }
}
